// For info on how to create dashboard cards refer to docs/DASHBOARD_CARDS.md
import { addMonths, addWeeks, formatISO } from 'date-fns';
import { useEffect, useState } from 'react';

import { ONE, ZERO } from '@/constants';
import {
  useCreateClientEvent,
  useCurrentUser,
  useGetClientEvents,
  useGetEvents,
  useSessionsDashboard,
  useSurveys,
  useUpdateClientEvent,
} from '@/hooks';
import {
  CardType,
  type Event,
  type HomeCardPropsWithoutCallbacks,
} from '@/types';
import {
  getAiWorkflowCardProps,
  getClientEventsCardsProps,
  getCoachSessionsCardProps,
  getEventCardProps,
  getMatchedWithCoachCardProps,
  getMatchingCoachCardProps,
  track,
} from '@/utils';

export const useHomeCards = () => {
  // TODO: Last remaining piece requires porting peer insights apis
  const { data: surveys, isLoading: isLoadingSurveys } = useSurveys();
  const { data: eventsData, isLoading: isLoadingEvents } = useGetEvents();
  const {
    data: clientEvents,
    isLoading: clientEventsLoading,
    refetch,
  } = useGetClientEvents();
  const { data: sessionsData } = useSessionsDashboard();
  const [cards, setCards] = useState<HomeCardPropsWithoutCallbacks[]>([]);
  const { user, isUserLoading } = useCurrentUser();
  const updateClientEvent = useUpdateClientEvent();
  const createClientEvent = useCreateClientEvent();
  const MAX_HOME_CARDS = 5;

  const onDismiss = (id: string) => {
    const card = cards.find((i) => i.id === id);
    if (!card) {
      return;
    }
    const index = cards.indexOf(card);
    const hasBeenDismissed = !!card.dismissedTill;

    track('Dismissed dashboard action card', {
      clientId: user.clientId,
      label: card.label,
      title: card.title,
    });
    // Optimiscally update array
    setCards([...cards.slice(ZERO, index), ...cards.slice(index + ONE)]);
    // Update card on BE
    const dismissedTill = hasBeenDismissed
      ? formatISO(addMonths(new Date(), ONE))
      : formatISO(addWeeks(new Date(), ONE));
    if (card.type === CardType.EVENT) {
      updateClientEvent.mutate(
        { dismissedTill, eventId: card.id, id: card.id, key: card.type },
        { onSuccess: () => refetch() },
      );
    } else if (card.id) {
      updateClientEvent.mutate(
        { dismissedTill, id: card.id, key: card.type },
        { onSuccess: () => refetch() },
      );
    } else {
      createClientEvent.mutate(
        { dismissedTill, key: card.type },
        { onSuccess: () => refetch() },
      );
    }
  };

  const parseEvents = ({
    events = [],
    pinned,
  }: {
    events: Event[];
    pinned?: boolean;
  }) =>
    events
      .filter((e) => (pinned ? e.isPinned : !e.isPinned))
      .map((e) => ({
        ...getEventCardProps(e),
        ctaLabel: e.ctaLabel,
        ctaLink: e.ctaLink,
        imageUrl: e.imageUrl,
        isImageInline: e.isImageInline,
      }));

  useEffect(() => {
    if (
      !surveys ||
      !sessionsData ||
      isUserLoading ||
      isLoadingEvents ||
      clientEventsLoading
    ) {
      return;
    }

    const aiWorkflowPrompt = [getAiWorkflowCardProps()];

    const matchingCoach = !user.coaches.length
      ? [getMatchingCoachCardProps()]
      : [];

    const getMatched = !user.userAssesment?.complete
      ? [getMatchedWithCoachCardProps()]
      : [];

    const coachesSessions = user.coaches.length
      ? getCoachSessionsCardProps({
          sessionsData,
          user,
        })
      : [];
    const clientEventsProps = getClientEventsCardsProps(
      clientEvents,
      surveys[0] || null,
      user,
    );

    setCards(() => [
      ...aiWorkflowPrompt,
      ...parseEvents({ events: eventsData || [], pinned: true }),
      ...matchingCoach,
      ...coachesSessions,
      ...clientEventsProps,
      ...getMatched,
      ...parseEvents({ events: eventsData || [] }),
    ]);
  }, [
    surveys,
    eventsData,
    sessionsData,
    isUserLoading,
    clientEventsLoading,
    isLoadingEvents,
  ]);

  return {
    cards: cards.slice(ZERO, MAX_HOME_CARDS),
    isLoading: isLoadingSurveys && clientEventsLoading && isLoadingEvents,
    onDismiss,
    refetch,
  };
};
