import { Icon, Typography } from '@/ui';

import type { Milestone } from '@/types';
import type { FC } from 'react';

interface MilestoneDataCTAProps {
  color: string;
  done: boolean;
  milestone: Milestone;
}
const MilestoneDataCTA: FC<MilestoneDataCTAProps> = ({
  milestone,
  color,
  done,
}) => {
  const noSteps = !milestone.totalSteps;
  if (noSteps) {
    return (
      <Typography
        className={`mb-0.5 ${color} flex items-center`}
        variant="body2"
      >
        <Icon className="mr-1" fontSize="small">
          magic_button
        </Icon>
        3 suggested steps
      </Typography>
    );
  }
  return (
    <Typography className={color} variant="body2">
      {done
        ? 'All steps complete!'
        : `${milestone.completeSteps} of ${milestone.totalSteps} complete`}
    </Typography>
  );
};

export default MilestoneDataCTA;
