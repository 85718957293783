import type { Mindset } from './client';
import type { CoachAssignment } from './coachAssignment';
import type { Company } from './company';
import type { UserEmployment } from './userEmployment';
import type { UserNotifications } from './userNotifications';

interface GrowthPlanSummary {
  id: string;
  northStar: string;
  oneYearVision: string;
  started: boolean;
  teamId: string | null;
}

export interface User {
  adminNote?: string;
  avatarUrl?: string;
  // legacy id
  // TODO: REMOVE ONCE FULL MIGRATION IS DONE
  clientId: string;
  coachAssignments?: CoachAssignment[];
  coachId: string;
  coachProfile?: CoachProfile;
  coacheeAssignments?: CoachAssignment[];
  coachees: User[];
  coaches: User[];
  cometChatId: string;
  createdAt: string; // Timestamp
  currentCompany?: Partial<Company>;
  documentsEmail?: string;
  email: string;
  emailUpdated: boolean;
  firstName: string;
  folderId?: string;
  fullName: string;
  growthPlans: GrowthPlanSummary[];
  hasCoaches: boolean;
  hasMaxDocuments: boolean;
  // TODO: Remove, only for now so we can manage teams users easily
  hasTeams: boolean;
  id: string;
  insightsStatus?: 'unsent' | 'in_progress' | 'shared' | 'unknown';
  isActive: boolean;
  isBusinessPaid: boolean;
  isCoach: boolean;
  isPaid: boolean;
  lastLogin?: string; // Timestamp
  lastName: string;
  // legacy growth fields
  // TODO: REMOVE ONCE GROWTH IS MIGRATED
  legacyGrowthPlanDone: boolean;
  legacyNorthStar: string;
  legacyOneYearVision: string;
  mindset: Mindset;
  profile?: UserProfile;
  starters: string[];
  sub: string;
  superAdmin: boolean;
  updatedAt: string; // Timestamp
  userAssesment?: UserAssesment;
  userEmployments?: UserEmployment[];
  userNotifications?: UserNotifications;
  userPayment?: UserPayment;
}

// Note: This mirrors the `status` enum in the BE UserPayment model. Updates here should match there.
export enum UserPaymentStatus {
  active = 'active',
  canceled = 'canceled',
  incomplete = 'incomplete',
  paused = 'paused',
}

export interface UserProfile {
  bio?: string;
  company?: string;
  createdAt: string; // Timestamp
  experience: string;
  linkedIn: string;
  location: string;
  matchPreference?: string;
  notifyEmail: boolean;
  notifySms: boolean;
  phone?: string;
  pronouns?: string;
  scheduleMatchCall?: string; // Timestamp
  timezone?: string;
  title?: string;
  updatedAt: string; // Timestamp
  userId: string;
}

export interface CoachProfile {
  bannerDismissedUntil?: string; // Timestamp
  bragSheet: string | null;
  calendarUrl?: string;
  capacity: number;
  focus: string;
  industries: Array<string>;
  role: Array<string>;
  styles: Array<string>;
  user: User;
  userId: string;
}

export interface UserPayment {
  createdAt: string; // Timestamp
  customerId: string;
  plan?: string;
  status: UserPaymentStatus;
  subscriptionId: string;
  updatedAt: string; // Timestamp
}

export interface UserAssesment {
  careerGoals: string[];
  challenges: string[];
  coachingNeeds: string[];
  complete?: boolean;
  dreamJobs: string[];
  interests: string[];
  motivations: string[];
}

export interface AboutForm {
  company: string;
  experience: string;
  firstName: string;
  lastName: string;
  title: string;
  linkedIn: string;
}
