/* eslint-disable react/iframe-missing-sandbox --  we need to embed loom and it's not possible with sandboxing */
import { useContext, useEffect } from 'react';

import { Button, Typography } from '@/ui';
import { track } from '@/utils';

import { GetStartedContext } from '@/components/GetStarted/context';

const OnboardingVideo = () => {
  const { nextStep } = useContext(GetStartedContext);

  useEffect(() => {
    track('ob_video');
  }, []);

  const onNext = () => {
    track('ob_video_cont');
    nextStep();
  };

  return (
    <main className="bg-gradient-dark-sunny flex flex-1 flex-col overflow-auto">
      <div className="flex flex-col pb-20 md:pb-0">
        <div className="container mx-auto p-4 text-center md:mt-4">
          <Typography className="mb-5" variant="h4">
            Our matching process in one minute
          </Typography>
          <div className="mx-auto mb-8 max-w-screen-md">
            <div
              className="md:block"
              style={{
                maxWidth: '600px',
                maxHeight: '360px',
                position: 'relative',
                width: '100%',
                margin: '0 auto',
              }}
            >
              <iframe
                frameBorder="0"
                src="https://www.loom.com/embed/c70468bb53e3493d8d2367598e03e5f2?sid=b4cc6639-3689-457d-b6bf-4ecd3c3e0c9d"
                style={{
                  height: '360px',
                  left: 0,
                  top: 0,
                  width: '100%',
                }}
                title="Welcome to Mento"
                allowFullScreen
              />
            </div>
          </div>
          <div className="mx-auto mb-2" style={{ maxWidth: '600px' }}>
            <div className="text-left">
              <Typography className="mb-4" variant="h5">
                How it works
              </Typography>
              <ol className="list-decimal space-y-3 pl-10">
                <li>
                  <Typography className="flex-1" component="div">
                    <Typography className="font-semibold" component="span">
                      Choose your matching style:{' '}
                    </Typography>
                    Complete a reflection or chat with our team
                  </Typography>
                </li>
                <li>
                  <Typography className="flex-1" component="div">
                    <Typography className="font-semibold" component="span">
                      Share your goals:{' '}
                    </Typography>
                    Answer a few questions to help us find your ideal coach
                  </Typography>
                </li>
                <li>
                  <Typography className="flex-1" component="div">
                    <Typography className="font-semibold" component="span">
                      Meet your matches:{' '}
                    </Typography>
                    Review coach options and schedule Chemistry Calls
                  </Typography>
                </li>
                <li>
                  <Typography className="flex-1" component="div">
                    <Typography className="font-semibold" component="span">
                      Find the right fit:{' '}
                    </Typography>
                    Connect with coaches to ensure alignment and trust
                  </Typography>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 mt-auto w-full px-6 pb-4 text-center text-sm md:static md:mb-6 md:mt-0 md:py-6">
        <Button
          className="w-full min-w-72 md:w-72"
          color="primary"
          variant="contained"
          onClick={onNext}
        >
          Let's get started
        </Button>
      </div>
    </main>
  );
};
/* eslint-enable react/iframe-missing-sandbox */
export default OnboardingVideo;
