import { Widget } from '@typeform/embed-react';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ONE } from '@/constants';
import { useCurrentUser } from '@/hooks';
import { Button, Typography } from '@/ui';
import { track } from '@/utils';

import { GetStartedContext } from '@/components/GetStarted/context';

const MatchingForm = () => {
  const navigate = useNavigate();
  const { user, isUserLoading } = useCurrentUser();
  const { nextStep } = useContext(GetStartedContext);

  useEffect(() => {
    track('ob_matching_form');
  }, []);

  if (isUserLoading) return null;

  return (
    <main className="bg-gradient-dark-sunny flex flex-1 flex-col overflow-auto">
      <div className="container mx-auto flex flex-1 flex-col p-4">
        <Typography className="mb-5 text-center" variant="h4">
          Async Match
        </Typography>
        <Typography
          className="mx-auto mb-8 max-w-100 text-center"
          variant="body1"
        >
          Complete a 10 minute reflection to help us make a great match
        </Typography>
        <Widget
          className="rounded-lg shadow-lg"
          hidden={{ mentoId: user.id }}
          id="MpRcBnik"
          style={{ width: '100%', height: '650px' }}
          onSubmit={() => nextStep(ONE)}
        />
        <div className="container mx-auto flex justify-center p-4">
          <Button
            variant="contained"
            onClick={() => navigate('/get-started/get-coach')}
          >
            ← Back to matching preferences
          </Button>
        </div>
      </div>
    </main>
  );
};

export default MatchingForm;
