import clsx from 'clsx';
import { type FC } from 'react';

import { stripeCheckout } from '@/api';
import { PlanTypes, type Price, type User } from '@/types';
import { Button, Chip, Icon, IconButton, Typography } from '@/ui';
import { getStripe, track } from '@/utils';

interface CardProps {
  price: Price;
  user: User;
}

const Card: FC<CardProps> = ({ user, price }) => {
  const { amount = 0, pill = '', sub, title, type } = price;

  const isExec = type === PlanTypes.exec;

  const onClick = async () => {
    track(`Click ${title} Payment Onboarding`);

    try {
      const successUrl = `${
        import.meta.env.VITE_APP_URL
      }/get-started/membership-paid`;
      const cancelUrl = `${
        import.meta.env.VITE_APP_URL
      }/get-started/membership-select`;
      const { sessionId } = await stripeCheckout(
        price,
        user,
        successUrl,
        cancelUrl,
      );

      const stripe = await getStripe();
      // TODO: What should happen if we can't get stripe? Display error?
      await stripe?.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className={clsx(
        'mt-6 flex max-w-full rounded-xl p-4 lg:ml-8 lg:mt-0 lg:max-w-xs lg:flex-col lg:p-8',
        {
          'bg-sage-100': !isExec,
          'bg-sky-100': isExec,
        },
      )}
    >
      <div className="w-full">
        <div className="flex flex-col gap-2 lg:block">
          <Typography
            className={clsx({
              'md:tracking-wide': !isExec,
            })}
            variant="h4"
          >
            {title}
          </Typography>

          <Chip
            className="mt-2 self-baseline"
            color="info"
            label={pill}
            size="medium"
          />
        </div>
        <div
          className={clsx('mt-2 flex items-baseline lg:!mt-6', {
            'text-sage-500': !isExec,
            'text-sky-500': isExec,
          })}
        >
          <Typography variant="h4">${amount}</Typography>
          <Typography variant="body2">&nbsp;/ month</Typography>
          <IconButton
            className={clsx('my-auto ml-auto lg:hidden', {
              'bg-sky-500 hover:bg-sky-400': isExec,
            })}
            size="large"
            variant="contained"
            onClick={onClick}
          >
            <Icon className="text-white">chevron_right</Icon>
          </IconButton>
        </div>
        {sub && (
          <Typography className="lg:mt-1.5 lg:h-6" variant="body2">
            {sub}
          </Typography>
        )}
        <Button
          className={clsx('mt-7 hidden lg:block', {
            'bg-sky-500 hover:bg-sky-600': isExec,
          })}
          variant="contained"
          fullWidth
          onClick={onClick}
        >
          Select plan
        </Button>
      </div>
    </div>
  );
};

export default Card;
