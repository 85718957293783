import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Typography } from '@/ui';
import { track } from '@/utils';

const OnboardingComplete = () => {
  const navigate = useNavigate();

  useEffect(() => {
    track('ob_complete');
  }, []);

  return (
    <main className="bg-gradient-dark-sunny flex flex-1 flex-col overflow-auto">
      <div className="container mx-auto flex flex-1 flex-col items-center justify-center p-4">
        <div className="max-w-xl text-center">
          <Typography className="mb-6" variant="h4">
            You're all set!
          </Typography>
          <Typography className="mb-4" variant="body1">
            We look forward to matching you with wonderful coaches.
          </Typography>
          <Typography className="mb-8" variant="body1">
            If you have any questions, please reach out to{' '}
            <a
              className="text-primary hover:underline"
              href="mailto:concierge@mento.co"
            >
              concierge@mento.co
            </a>
          </Typography>
          <Button
            size="large"
            variant="contained"
            onClick={() => navigate('/m')}
          >
            Go to your Mento account
          </Button>
        </div>
      </div>
    </main>
  );
};

export default OnboardingComplete;
