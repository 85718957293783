import { init as FullStoryInit } from '@fullstory/browser';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { captureException } from '@sentry/react';
import { StytchProvider } from '@stytch/react';
import { StytchUIClient } from '@stytch/vanilla-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import AppRouter from '@/Router';
import { CssBaseline, GlobalCssPriority, theme, ThemeProvider } from '@/ui';
import { trackingInit } from '@/utils';

import { MILLISECONDS_IN_FIVE_MINUTES } from './constants';
import AppProvider from './context/provider';
import { retryHandler } from './utils/retryHandler';

import type { QueryClientConfig } from '@tanstack/react-query';

import './index.css';

const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: retryHandler,
      staleTime: MILLISECONDS_IN_FIVE_MINUTES,
    },
  },
};

const queryClient = new QueryClient(queryClientConfig);

if (import.meta.env.VITE_FULLSTORY_ORG) {
  try {
    FullStoryInit({ orgId: import.meta.env.VITE_FULLSTORY_ORG });
  } catch (e) {
    captureException(e);
  }
}

// Mix Panel
trackingInit();

const stytch = new StytchUIClient(import.meta.env.VITE_STYTCH_PUBLIC_TOKEN);

const rootElement = document.getElementById('main') as HTMLElement;

ReactDOM.createRoot(rootElement).render(
  <StrictMode>
    <StytchProvider stytch={stytch}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <GlobalCssPriority>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CssBaseline />
              <BrowserRouter>
                <AppProvider>
                  <AppRouter />
                </AppProvider>
              </BrowserRouter>
            </LocalizationProvider>
          </ThemeProvider>
        </GlobalCssPriority>
      </QueryClientProvider>
    </StytchProvider>
  </StrictMode>,
);
