import { useEffect, useState } from 'react';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';

import { AppRoutes } from '@/Routes';
import { createPortalLink } from '@/api';
import { useApiClientInterceptors, useCurrentUser, useView } from '@/hooks';
import { AppMessageSeverities, UserPaymentStatus } from '@/types';
import { Alert, Snackbar } from '@/ui';
import { track, trackView } from '@/utils';

import { AppContext } from '.';

import type { AppMessage, MatchRouterParams } from '@/types';
import type { FC, PropsWithChildren } from 'react';
import type { RouteObject } from 'react-router-dom';

import LoadingCover from '@/components/Layouts/Loading';
import Widget from '@/components/Widget';

type Match = {
  params: { [key: string]: Object | string };
  route?: RouteObject;
};

const DEFAULT_APP_MESSAGE: AppMessage = {
  message: '',
  open: false,
  severity: AppMessageSeverities.Success,
};

function handleTrackView(matches: Match[]) {
  // To handle nested routes, we need to join all of the paths
  const path = matches
    .map((match) => {
      const data: MatchRouterParams = { ...match.params };
      delete data.token;
      delete data.email;
      return Object.keys(data).reduce(
        (a, p) => a.replace(`:${p}`, `[${p}]`),
        match.route?.path ?? '',
      );
    })
    .join('/');

  const data = {};

  if (path) {
    trackView(path, Object.keys(data).length ? data : undefined);
  }
}

// WARNING: API calls made inside the context will run before the interceptors
// are finished setting up
const AppProvider: FC<PropsWithChildren> = ({ children = undefined }) => {
  const { isClient, isCoach } = useView();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [chatId, setChatId] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [appMessage, setAppMessage] = useState(DEFAULT_APP_MESSAGE);

  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedTeamId, setSelectedTeamId] = useState('');

  const [interceptorsSetup, setInterceptorsSetup] = useState(false);
  const [reviewing, setReviewing] = useState(false);
  const [reviewed, setReviewed] = useState(false);

  const { user, isUserLoading, userEnabled } =
    useCurrentUser(interceptorsSetup);

  // Show Snackbar messages on API errors
  useApiClientInterceptors(setAppMessage, setInterceptorsSetup);

  useEffect(() => {
    const matches = matchRoutes(AppRoutes, { pathname }) as Match[];
    handleTrackView(matches);

    let userId = '';
    let teamId = '';
    matches.forEach((match) => {
      if (match.route?.handle?.selectedUserId) {
        userId = match.params[match.route.handle.selectedUserId] as string;
      }
      if (match.route?.handle?.selectedTeamId) {
        teamId = match.params[match.route.handle.selectedTeamId] as string;
      }
    });
    setSelectedUserId(userId);
    setSelectedTeamId(teamId);
  }, [pathname]);

  const setSelectedChatId = (value: string) => {
    if (value) {
      track('Open Chat Bubble', { recipient: chatId });
    }

    setChatId(value || '');
  };

  const handleCloseSnack = () => setAppMessage(DEFAULT_APP_MESSAGE);

  const checkUserStatus = async () => {
    const {
      email,
      isActive = false,
      isBusinessPaid = false,
      isPaid = false,
      userAssesment: { complete = false } = {},
      userPayment,
    } = user;

    const { status, subscriptionId } = userPayment || {
      status: null,
      subscriptionId: null,
    };

    if (pathname.startsWith('/get-started')) return;

    if (!complete) {
      navigate('/get-started/about');
      return;
    }

    if (isBusinessPaid) return;

    if (!isActive || status === UserPaymentStatus.canceled) {
      const data = await createPortalLink(user);
      if (data.url) {
        // external url, so use window.location instead of navigate
        window.location = data.url;
      } else {
        navigate('/');
      }
      return;
    }

    if (isPaid) return;

    if (!subscriptionId) {
      navigate(`/get-started/membership-select?email=${email}`);
    }
  };

  useEffect(() => {
    if (isUserLoading || !user.cometChatId) return;
    setSessionId(user.cometChatId);
  }, [isUserLoading, user.cometChatId]);

  useEffect(() => {
    if (pathname === '/' || isUserLoading || !user.id) return;

    (async () => {
      if (isClient && !reviewed && !reviewing) {
        setReviewing(true);
        await checkUserStatus();
        setReviewing(false);
        setReviewed(true);
      }
    })();
  }, [isClient, isUserLoading, user.id, pathname]);

  // if user is loaded in and randomly got to the coach profile without being a coach, redirect
  useEffect(() => {
    // user is loading or hook is disabled. do nothing.
    if (isUserLoading || !userEnabled) return;

    // user is on coach routes and is not a coach!
    if (isCoach && !!user.id && !user.isCoach) {
      navigate('/m');
    }
  }, [isCoach, user, isUserLoading, userEnabled]);

  const hiddenOnPaths =
    pathname.startsWith('/get-started') ||
    pathname.includes('signin') ||
    pathname.includes('authenticate') ||
    pathname.includes('membership-expired') ||
    pathname.includes('workflow/') ||
    pathname.includes('/workflow');

  return (
    <AppContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values -- TODO: Evaluate if we should useMemo here.
      value={{
        chatId,
        selectedTeamId,
        selectedUserId,
        setAppMessage,
        setChatId: setSelectedChatId,
      }}
    >
      {interceptorsSetup ? children : <LoadingCover />}

      <Widget
        chatId={chatId}
        hideChat={!sessionId || hiddenOnPaths}
        sessionId={sessionId}
        setChatId={setChatId}
      />

      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        autoHideDuration={2500}
        open={appMessage.open}
        onClose={handleCloseSnack}
      >
        <Alert
          className="w-full"
          severity={appMessage.severity}
          onClose={handleCloseSnack}
        >
          {appMessage.message}
        </Alert>
      </Snackbar>
    </AppContext.Provider>
  );
};

export default AppProvider;
