import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { Button, Icon, Link, List, Typography } from '@/ui';

import Step from './Step';

import type { MilestoneStep } from '@/types';
import type { FC } from 'react';

interface StepsProps {
  id: string;
  steps: MilestoneStep[];
  title: string;
  totalSteps?: number;
}
const Steps: FC<StepsProps> = ({ title, steps, id, totalSteps = 0 }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const titleTag =
    id && !steps[0]?.completedAt ? (
      <Link
        className="item-center flex no-underline hover:underline"
        color="text.secondary"
        component={RouterLink}
        to={`${pathname}/milestone/${id}`}
      >
        {title}
        <Icon className="ml-2" fontSize="small">
          chevron_right
        </Icon>
      </Link>
    ) : (
      title
    );

  const handleOnClick = () => navigate(`${pathname}/milestone/${id}`);
  const hasSteps = steps.length || totalSteps;

  if (!hasSteps) {
    return (
      <div className="mb-10">
        <Typography className="mb-3" color="text.secondary" variant="subtitle2">
          {titleTag}
        </Typography>

        <Typography className="mb-3">
          No steps, yet. We have a few suggestions to get you started:
        </Typography>

        <Button
          color="secondary"
          size="medium"
          startIcon={<Icon>magic_button</Icon>}
          variant="contained"
          light
          onClick={handleOnClick}
        >
          View 3 suggested steps
        </Button>
      </div>
    );
  }

  if (!steps.length) {
    return null;
  }

  return (
    <div className="mb-10">
      <Typography className="mb-3" color="text.secondary" variant="subtitle2">
        {titleTag}
      </Typography>

      <List>
        {steps.map((step) => (
          <Step key={step.id} step={step} />
        ))}
      </List>
    </div>
  );
};

export default Steps;
