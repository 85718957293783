import clsx from 'clsx';
import { useContext } from 'react';

import { FIVE, FOUR, ONE, SEVEN, SIX, TWO } from '@/constants';
import { Icon, Typography } from '@/ui';

import type { FC } from 'react';

import { GetStartedContext } from '@/components/GetStarted/context';

const GetStartedNav: FC = () => {
  const { currentStep } = useContext(GetStartedContext);

  return (
    <div>
      <div className="flex w-full items-center justify-between">
        <div className="flex w-full pt-2 md:w-auto md:pl-10 md:pt-0">
          <img
            alt="logo"
            className="mx-auto"
            height="40"
            src="https://mento-space.nyc3.digitaloceanspaces.com/logo.svg"
            width="100"
          />
        </div>
        <div className="ml-auto hidden w-4/6 min-w-10 grid-cols-3 items-center gap-1 px-10 py-6 md:grid lg:gap-3">
          <div>
            <Typography
              className={clsx('flex items-center', {
                'text-cement-300': currentStep >= TWO,
              })}
              variant="subtitle1"
            >
              Introduce yourself
              <div className="h-7">
                {currentStep >= TWO && (
                  <Icon className="font-sm pl-2 text-cement-300">check</Icon>
                )}
              </div>
            </Typography>
            <div
              className={clsx(
                'mt-2 flex rounded-full bg-cement-300 transition-all duration-1000 ease-out',
                {
                  'opacity-0': currentStep >= TWO,
                },
              )}
            >
              <div
                className={clsx(
                  'h-1 rounded-full bg-sunny-500 transition-all duration-1000 ease-out',
                  {
                    'w-1/2': currentStep === ONE,
                    'w-full': currentStep === TWO,
                  },
                )}
              />
            </div>
          </div>
          <div>
            <Typography
              className={clsx('title flex items-center', {
                'text-cement-300': currentStep >= FOUR,
              })}
              variant="subtitle1"
            >
              Learn about matching
              <div className="h-7">
                {currentStep >= FOUR && (
                  <Icon className="font-sm pl-2 text-cement-300">check</Icon>
                )}
              </div>
            </Typography>
            <div
              className={clsx(
                'mt-2 flex rounded-full bg-cement-300 transition-all duration-1000 ease-out',
                {
                  'opacity-0': currentStep >= FOUR,
                },
              )}
            >
              <div
                className={clsx(
                  'h-1 w-0 rounded-full bg-sunny-500 transition-all duration-1000 ease-out',
                )}
              />
            </div>
          </div>
          <div>
            <Typography
              className={clsx('title flex items-center', {
                'text-cement-300': currentStep >= SEVEN,
              })}
              variant="subtitle1"
            >
              Get matched
              <div className="h-7">
                {currentStep >= SEVEN && (
                  <Icon className="font-sm pl-2 text-cement-300">check</Icon>
                )}
              </div>
            </Typography>
            <div
              className={clsx(
                'mt-2 flex rounded-full bg-cement-300 transition-all duration-1000 ease-out',
                {
                  'opacity-0': currentStep >= SEVEN,
                },
              )}
            >
              <div
                className={clsx(
                  'h-1 rounded-full bg-sunny-500 transition-all duration-1000 ease-out',
                  {
                    'w-1/2': currentStep === FIVE || currentStep === SIX,
                    'w-full': currentStep === SEVEN,
                  },
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStartedNav;
